const SearchMenu = (props) => {
  return (
    <div id="navigationPc1" className="navigationPcClearBoth s12 m12 l2">
      <ul className="collapsible">
        <li className={props.searchCollapsibleAclive}>
          <div className="collapsible-header">
            <span className="tooltipped notranslate" data-position="right" data-tooltip={props.lSearchOpenAndClose}>
              <img src="img/search.png" width="14" height="14" alt="search" />&nbsp;{props.lSearchSearch}
            </span>
            <span className="tooltipped notranslate" data-position="right" data-tooltip={props.lSearchOpenAndClose}>
              <i className="material-icons notranslate">expand_more</i>
            </span>
          </div>
          <div className="collapsible-body">
            <dl>
              <dd className="sidemenu">
                  {props.lSearchKeyword}
                  <div dangerouslySetInnerHTML={{__html: props.formSearchMallKeywords}} />
                  <div dangerouslySetInnerHTML={{__html: props.formSearchMallSearchAndOr}} />
                  {props.lSearchPrice} {props.priceMark}
                  <div dangerouslySetInnerHTML={{__html: props.formSearchMallPriceMin}} />
                  {props.lSearchPriceFrom}
                  <div dangerouslySetInnerHTML={{__html: props.formSearchMallPriceMax}} />
                  {props.lSearchPriceTo}
              </dd>
              <dd className="sidemenu">
                <span className="tooltipped notranslate" data-position="right" data-tooltip={props.lSearchClearSearchConditions}>
                  <a href={'?clear=1' + props.pageURLOption} className="hoverGlay loading">{props.lSearchReset}</a>
                </span>
                &nbsp;
        {/* <input type="hidden" name="ivmode" value="0">*/}
                <div className="loading" dangerouslySetInnerHTML={{__html: props.prevLinkTag}} />
                <div className="loading" dangerouslySetInnerHTML={{__html: props.nextSubmitTag}} />
              </dd>
            </dl>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default SearchMenu;
