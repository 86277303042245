import { useEffect } from 'react';

//import logo from './logo.svg';
import './SearchMenuApp.css';
import SearchMenu from "./components/SearchMenu";


/* PHP�e���v���[�g���ňȉ����L�q���Ă��� 
window.reactPageURLOption = '{{g_pageURLOption}}';
window.reactSearchCollapsibleAclive = '{{g_search_collapsible_aclive}}';
window.reactLSearchOpenAndClose = '{{l_search:open_and_close}}';
window.reactLSearchSearch = '{{l_search:search}}';
window.reactLSearchClearSearchConditions = '{{l_search:clear_search_conditions}}';
window.reactLSearchReset ='{{l_search:reset}}';
window.reactLSearchKeyword = '{{l_search:keyword}}';
window.reactFormSearchMallKeywords = '{{g_form_search_mall__keywords}}';
window.reactFormSearchMallSearchAndOr = '{{g_form_search_mall__search_and_or}}';
window.reactLSearchPrice = '{{l_search:price}}';
window.reactFormSearchMallPriceMin = '{{g_form_search_mall__price_min}}';
window.reactLSearchPriceFrom = '{{l_search:price_from}}';
window.reactFormSearchMallPriceMax = '{{g_form_search_mall__price_max}}';
window.reactLSearchPriceTo = '{{l_search:price_to}}';
window.reactPriceMark = '{{g_price_mark}}';
window.reactPrevLinkTag = '{{prev_link_tag}}';
window.reactNextSubmitTag = '{{next_submit_tag}}';
*/

const SearchMenuApp = () => {

  useEffect(()=>{
  //console.log('useEffect.')
    window.jQuery('select').formSelect();
    window.jQuery('.collapsible').collapsible();
    window.jQuery('.tooltipped').tooltip();

    window.jQuery('.preloader-wrapper').css('position', 'absolute');
    window.jQuery('.preloader-wrapper').css('z-index', -1);
    window.jQuery(".preloader-wrapper").removeClass('active');
    window.jQuery(".loading").on('click',function(event){
      var x = event.clientX -30;
      var y = event.clientY + window.scrollY -30;
      window.jQuery('.preloader-wrapper').css('position', 'absolute');
      window.jQuery('.preloader-wrapper').css('z-index', 1000);
      window.jQuery('.preloader-wrapper').css('top', y);
      window.jQuery('.preloader-wrapper').css('left', x);
      window.jQuery(".preloader-wrapper").addClass('active');
      return true;
    });

  });

  return (
    <div className="SearchMenuApp">
      <SearchMenu
        pageURLOption = {window.reactPageURLOption}
        searchCollapsibleAclive = {window.reactSearchCollapsibleAclive}
        lSearchOpenAndClose = {window.reactLSearchOpenAndClose}
        lSearchSearch = {window.reactLSearchSearch}
        lSearchClearSearchConditions = {window.reactLSearchClearSearchConditions}
        lSearchReset = {window.reactLSearchReset}
        lSearchKeyword = {window.reactLSearchKeyword}
        formSearchMallKeywords = {window.reactFormSearchMallKeywords}
        formSearchMallSearchAndOr = {window.reactFormSearchMallSearchAndOr}
        lSearchPrice = {window.reactLSearchPrice}
        formSearchMallPriceMin = {window.reactFormSearchMallPriceMin}
        lSearchPriceFrom = {window.reactLSearchPriceFrom}
        formSearchMallPriceMax = {window.reactFormSearchMallPriceMax}
        lSearchPriceTo = {window.reactLSearchPriceTo}
        priceMark = {window.reactPriceMark}
        prevLinkTag = {window.reactPrevLinkTag}
        nextSubmitTag = {window.reactNextSubmitTag}
      />
    </div>
  );
}

export default SearchMenuApp;
